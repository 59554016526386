import React from "react"
import LogoFrame from '../../assets/images/logo-frame.png'
import KaldiGoatWalking from '../../assets/images/kaldi-goat-walking.gif'
import InstagramLogo from '../../assets/images/logo-instagram.svg'

const Hero = () => {
  return (
    <div className='hero__container'>
      <div className='hero__frame'>
        <img src={LogoFrame} alt="Cafe de especialidad" />
      </div>
      <div className='hero__logo'>
        <img src={KaldiGoatWalking} alt="Kaldi" />
      </div>
      <div className='hero__social'>
        <ul className='hero__social-container'>
          <li><a href='https://instagram.com/kaldi.uy' alt='Instagram Kaldi'><img src={InstagramLogo} /></a></li>
        </ul>
      </div>
    </div>
  );
};

export default Hero;
